import {useState, useEffect, useCallback} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';

import {RootState} from '@/app/rootReducer';

import {AppDispatch} from '@/app/store';

import {useDispatch, useSelector} from 'react-redux';

import utils from '@/lib/utils';
import constants from '@/config/constants';
import {
  updateSearchWordsSales,
  fetchSales,
  updateActiveSales,
} from '@/redux/salesSlice';

const SALE_CLOSED = constants.STATUS.DONE;
export const CLOSED_SORT_COND = '-fixed_at';
export const DEFAULT_SORT_COND = '-posted_at';

// 出品した魚全てを取得する
const allSaleFishes = [
  constants.STATUS.DISPLAYING,
  constants.STATUS.DONE,
  constants.STATUS.CANCELED,
  constants.STATUS.EXPIRED,
  constants.STATUS.BANNED,
  constants.STATUS.ERROR,
];

export type SearchArgs = {
  sort?: string;
  current?: number;
  search?: {words: string[]};
};

export const useSalesTable = ({
  sort = DEFAULT_SORT_COND,
  current = 1,
  isBid = false,
} = {}) => {
  const dispatch: AppDispatch = useDispatch();
  const {me, sales} = useSelector((state: RootState) => ({
    sales: state.sales,
    me: state.me,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [isPaging, setIsPaging] = useState(true);

  const searchWord = useCallback(
    (value: string) => {
      dispatch(updateSearchWordsSales({word: value.trim()}));
    },
    [dispatch]
  );

  const currentPagination = useCallback(
    async ({
      sort = sales.pagination.sort,
      current = 1,
      search = sales.search,
    }) => {
      dispatch(
        updateActiveSales({
          pages: sales.pagination.data,
          current: sales.pagination.current,
          nextPage: current,
          totalPages: sales.pagination.totalPages,
        })
      );

      try {
        setIsLoading(true);
        setIsPaging(true);

        const action = await dispatch(
          fetchSales({
            sort,
            seller: me.id,
            page: current,
            // closed: isBid ? [SALE_CLOSED] : allSaleFishes,
            closed: allSaleFishes,
            fixed: isBid ? 1 : undefined,
            search,
          })
        );

        unwrapResult(action);
      } catch (err) {
        utils.errors.handleBasics(err);
      } finally {
        setIsLoading(false);
        setIsPaging(false);
      }
    },
    [dispatch, me.id, sales.pagination, sales.search, isBid]
  );

  const changeSort = useCallback(
    async (sort: string = DEFAULT_SORT_COND, current: number = 1) => {
      try {
        setIsLoading(true);
        setIsPaging(true);

        const action = await dispatch(
          fetchSales({
            sort,
            seller: me.id,
            page: current,
            // closed: isBid ? [SALE_CLOSED] : allSaleFishes,
            closed: allSaleFishes,
            fixed: isBid ? 1 : undefined,
            search: sales.search,
          })
        );

        unwrapResult(action);
      } catch (err) {
        utils.errors.handleBasics(err);
      } finally {
        setIsLoading(false);
        setIsPaging(false);
      }
    },
    [dispatch, me.id, sales.search, isBid]
  );

  useEffect(() => {
    currentPagination({sort, current});
    // eslint-disable-next-line
  }, [isBid]);

  return {searchWord, currentPagination, changeSort, isLoading, isPaging};
};
