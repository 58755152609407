import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {getOneStock} from '@/redux/stockSlice';
import {getShippingCosts} from '@/api/shippingCosts';

export const useGetStock = (
  setCost: React.Dispatch<React.SetStateAction<{id: number; userId: number}>>
) => {
  const {
    params: {stockId},
  } = useRouteMatch<{stockId: string}>();

  const {data, userId, isCompany, shippingProvider} = useSelector((state) => {
    return {
      userId: state.me.id,
      isCompany: state.me.isCompany,
      shippingProvider: state.me.shippingProvider,
      data: state.stocks.data,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const id = parseInt(stockId, 10);

    dispatch(getOneStock(id));
  }, [dispatch, stockId]);

  useEffect(() => {
    const fetch = async (userId: number) => {
      try {
        const res: any = await getShippingCosts(userId);
        setCost({
          id: res.data.id,
          userId: res.data.user_id,
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetch(userId);
  }, [userId, setCost]);

  return {data, userId, isCompany, shippingProvider, stockId};
};
