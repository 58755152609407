import {now, subDate, addDate} from '@/lib/utils/date';

/** 地域 */
export const areas = [
  '北海道地方',
  '東北地方',
  '関東地方',
  '中部地方',
  '近畿地方',
  '中国・四国地方',
  '九州地方',
  '沖縄地方',
].map((text, index) => ({
  text,
  value: index + 1,
}));

/** 処理方法 */
export const cleaningMethods = [
  {
    value: 10,
    text: 'なし',
  },
  ...[
    '内臓処理',
    '内臓処理、血わた処理',
    '血抜き、内臓処理',
    '血抜き、内臓処理、血わた処理',
    '血抜き→神経締め、内臓処理',
    '血抜き→神経締め、内臓処理、血わた処理',
    '血抜き→神経締め→水抜き、内臓処理',
    '血抜き→神経締め→水抜き、内臓処理、血わた処理',
    '津本式・究極の血抜き（血抜き→神経締め→水抜き、内臓処理、血わた処理）',
  ].map((text, index) => ({
    text,
    value: index + 1,
  })),
];

/** 出品期限 */
export const closeBidMins = [
  {
    text: '1日',
    value: 24,
  },
  {
    text: '2日',
    value: 48,
  },
  {
    text: '3日',
    value: 72,
  },
  {
    text: '5日',
    value: 120,
  },
  {
    text: '7日',
    value: 168,
  },
  {
    text: '10日',
    value: 240,
  },
];

/** 配送方法 */
export const deliveryMethods = ['常温', '冷蔵', '冷凍'].map((text, index) => ({
  text,
  value: index + 1,
}));

/** 釣り場 */
export const fishingSpots = [
  '未選択',
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
].map((text, index) => ({
  text,
  value: index,
}));

/** 締め方 */
export const ikejimeMethods = [
  {
    text: '氷締め（真水）',
    value: 101,
  },
  {
    text: '氷締め（塩水）',
    value: 102,
  },
  {
    text: '脳天締め',
    value: 103,
  },
  {
    text: '活魚',
    value: 105,
  },
  {
    text: '不明',
    value: 104,
  },
  // {
  //   key: '5',
  //   value: 105,
  //   text: '活〆',
  // },
  // {
  //   key: '6',
  //   value: 106,
  //   text: '活魚',
  // },
];

/** 締め方 */
export const ikejimeWaters = [
  {
    text: '選択なし',
    value: 0,
  },
  {
    text: 'オゾン水',
    value: 1,
  },
  {
    text: '電解水',
    value: 2,
  },
];

/** 締め処理を行なったタイミング */
export const ikejimeTimings = [
  '未選択',
  '直後',
  '～3時間',
  '～6時間',
  '～12時間',
  '～24時間',
  '24時間～',
].map((text, index) => ({
  text,
  value: index,
}));

/** 保管方法 */
export const savingMethods = [
  'クーラーボックス（保冷）',
  '冷蔵庫',
  '冷蔵庫（チルド室）',
  '冷凍庫',
  '冷凍庫（家庭用・急速冷凍）',
  '冷凍庫（家庭用・液体急速凍結・真空）',
  '冷凍庫（業務用・急速冷凍）',
  '冷凍庫（業務用・液体急速凍結・真空）',
].map((text, index) => ({
  text,
  value: index + 1,
}));

/** 配送料の負担 */
export const shippingExpenses = [
  '送料込み（出品者が負担）',
  '着払い（落札者が負担）',
  '元払い（落札者が負担）',
].map((text, index) => ({
  text,
  value: index + 1,
}));

/** 発送までの日数 */
export const shippingTimings = [
  '落札当日に発送',
  '落札翌日に発送',
  '落札2日後に発送',
  '落札5日以内に発送',
  '落札10日以内に発送',
].map((text, index) => ({
  text,
  value: index + 1,
}));

const format = 'YYYY';

// 過去2年
export const years = [
  {
    value: '',
    text: '',
  },
  {
    value: now(format),
    text: now(format),
  },
  {
    value: subDate('years', 1, format),
    text: subDate('years', 1, format),
  },
  {
    value: subDate('years', 2, format),
    text: subDate('years', 2, format),
  },
];

// 年
export const featureYears = [
  {
    value: now(format),
    text: now(format),
  },
  {
    value: addDate('years', 1, format),
    text: addDate('years', 1, format),
  },
];

/** 月 */
export const months = [...Array(13)].map((_, index) => {
  if (index === 0) return {text: '', value: ''};

  return {
    text: index,
    value: index,
  };
});

/** 月 */
export const days = [...Array(32)].map((_, index) => {
  if (index === 0) return {text: '', value: ''};

  return {
    text: index,
    value: index,
  };
});

/** 配送サイズ区分 */
export const shippingSizes = [60, 80, 100, 120, 140, 160, 170].map((value) => {
  return {
    text: value.toString(),
    value,
  };
});
