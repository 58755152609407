import React, {useState} from 'react';
import {Image, Dimmer, Loader} from 'semantic-ui-react';
import {Formik, FormikProps, Field} from 'formik';

import utils from '@/lib/utils';
import constants from '@/config/constants';
import {Form, Button} from '@/components';
import * as handlers from './handlers';
import * as hooks from './hooks';

import './index.scss';

export const SignInMain: React.FC<any> = (props) => {
  const [activeDimmer, setActiveDimmer] = useState(false);
  const [isFacebookLogin, setIsFacebookLogin] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  hooks.useMount(props, setActiveDimmer, setIsFacebookLogin, setIsGoogleLogin);

  return (
    <div className="a__signIn">
      <div className="b__image">
        <Image src={constants.IMAGES.LOGO} size="medium" />
      </div>

      <div className="b__form">
        <div className="title">
          <h1>Fish Sale</h1>
        </div>
        <Formik initialValues={initialValues} onSubmit={handlers.submit(props)}>
          {(formProps: FormikProps<any>) => {
            return (
              <form className="w__form">
                <Field
                  name="email"
                  component={Form.Input.Block}
                  placeholder="メールアドレス"
                  autoComplete="email"
                  disabled={formProps.isSubmitting}
                  dark
                  fluid
                  containerClass="bottom10"
                />

                <Field
                  type="password"
                  name="password"
                  component={Form.Input.Block}
                  placeholder="パスワード"
                  autoComplete="password"
                  disabled={formProps.isSubmitting}
                  dark
                  fluid
                  containerClass="bottom10"
                />

                <div className="messages">
                  Fish Saleでご登録のアカウントでログインしてください
                </div>

                <div className="w__actions">
                  <div>
                    <Button.Block
                      fluid
                      loading={formProps.isSubmitting}
                      disabled={
                        formProps.isSubmitting ||
                        isFacebookLogin ||
                        isGoogleLogin
                      }
                      color="main"
                      onClick={() => {
                        formProps.handleSubmit();
                      }}
                    >
                      サインイン
                    </Button.Block>
                  </div>
                  <div style={{marginTop: 8}}>
                    <Button.SNS
                      pattern="facebook"
                      fluid
                      loading={isFacebookLogin}
                      disabled={
                        formProps.isSubmitting ||
                        isFacebookLogin ||
                        isGoogleLogin
                      }
                      onClick={() => {
                        setIsFacebookLogin(true);
                        utils.auth.signInWitSNS('Facebook');
                      }}
                    >
                      Facebookでログイン
                    </Button.SNS>
                  </div>
                  <div style={{marginTop: 8}}>
                    <Button.SNS
                      pattern="google"
                      fluid
                      loading={isGoogleLogin}
                      disabled={
                        formProps.isSubmitting ||
                        isFacebookLogin ||
                        isGoogleLogin
                      }
                      onClick={() => {
                        setIsGoogleLogin(true);
                        utils.auth.signInWitSNS('Google');
                      }}
                    >
                      Googleでログイン
                    </Button.SNS>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <Dimmer active={activeDimmer} inverted>
        <Loader inverted />
      </Dimmer>
    </div>
  );
};
