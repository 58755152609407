import utils from '@/lib/utils';
import {AxiosPromise} from 'axios';

export const getShippingCosts = (
  userId: number
): AxiosPromise<ShippingCosts> => {
  return utils.axios.api().get(`/v1/shipping_costs/${userId}`);
};

export const updateShippingCosts = (
  userId: number,
  body: UpdateSippingCostsBody
): Promise<any> => {
  return utils.axios.api().put(`/v1/shipping_costs/${userId}`, body);
};
