import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Image} from 'semantic-ui-react';

import constants from '@/config/constants';
import paths from '@/config/paths';

import './index.scss';

const Sidebar: React.FC = () => {
  return (
    <div className="a__sidebar">
      <ul>
        <li className="logo">
          <Link to={paths.sales.main} className="w__logo">
            <Image src={constants.IMAGES.LOGO} circular />
          </Link>
        </li>

        <li>
          <NavLink to={paths.sales.main} activeClassName="active">
            <span className="title">出品魚一覧</span>
          </NavLink>
        </li>

        <li>
          <NavLink to={paths.stocks.main} activeClassName="active">
            <span className="title">在庫一覧</span>
          </NavLink>
        </li>

        <li>
          <NavLink to={paths.shippingCosts.main} activeClassName="active">
            <span className="title">元払い運賃設定</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
