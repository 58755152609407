import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchStocksPaging} from '@/redux/stockSlice';
import {Link} from 'react-router-dom';
import moment from 'moment';

const maxPrice = 2147483647;

export const useDidMount = () => {
  const {stocks} = useSelector((state) => ({
    stocks: state.stocks,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStocksPaging({limit: 10, page: 1, sort: '-id'}));
    // eslint-disable-next-line
  }, []);

  const {data} = makePaginationData(stocks.pagination.data.active);

  return {stocks, data};
};

const makePaginationData = (active: any) => {
  const data = active.map((d: any) => {
    return {
      ...d,
      closedReason: d.isRepeat ? '自動出品中' : '自動出品停止中',
      fishKind: <Link to={`/stocks/${d.id}`}>{d.fishKind}</Link>,
      fixPrice:
        d.fixPrice === maxPrice ? '-' : `¥${d.fixPrice.toLocaleString()}`,
      startPrice: `¥${d.startPrice.toLocaleString()}`,
      createdAt: d.createdAt && moment(d.createdAt).format('YYYY/MM/DD'),
    };
  });

  return {data};
};
