import utils from '@/lib/utils';
import qs from 'qs';

type ExportCsvParams = {
  sale_id: number;
}[];

export const exportCsv = async (qp: any): Promise<any> => {
  const _qp = qs.stringify({sale_id: qp}, {indices: false});
  const res = await utils.axios.api().get(`/v1/sales_csv?${_qp}`);

  return res.data;
};
