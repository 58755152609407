import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import {Formik, FormikProps, FormikValues} from 'formik';

import Tab from './Tab';
import Form from './Form';
import ExhibitButtons from './ExhibitButtons';
import StockButtons from './StockButtons';

import {initialState} from './data';
import {useSetData} from './useSetData';
import {useGetStock} from './useGetStock';
import {create} from '@/lib/validations/stocks';

import {submit} from './handlers';

import './index.scss';

export const StocksDetail: React.FC = () => {
  const [cost, setCost] = useState({id: 0, userId: 0});
  const [currentTab, setCurrentTab] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [initialValues, setInitialValues] = useState(initialState);

  const history = useHistory();
  const {data, isCompany, shippingProvider} = useGetStock(setCost);
  useSetData(data, setInitialValues);

  return (
    <div className="a__stocks-detail">
      <div className="title">
        <h2>出品魚の詳細</h2>
      </div>

      <div className="b__stocks">
        <div className="b__stocks-detail__main">
          <div className="a__stocks-detail__form">
            <Tab
              active={currentTab}
              setCurrentTab={setCurrentTab}
              isRepeat={data.isRepeat}
            />

            {/* 自動再出品 */}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={() => {}}
            >
              {(formProps: FormikProps<FormikValues>) => (
                <div
                  className={classNames('exhibit', {
                    hidden: !currentTab,
                  })}
                >
                  <Form
                    formProps={formProps}
                    exhibit
                    cost={cost}
                    isCompany={isCompany}
                    shippingProvider={shippingProvider}
                  />
                  <ExhibitButtons
                    id={data.id}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              )}
            </Formik>

            {/* 在庫 */}
            <Formik
              enableReinitialize
              validate={create}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={initialValues}
              onSubmit={async (values, actions) => {
                await submit(
                  values.id,
                  values,
                  actions,
                  history,
                  setUpdateModal
                );
              }}
            >
              {(formProps: FormikProps<FormikValues>) => (
                <div
                  className={classNames('stocks', {
                    hidden: currentTab,
                  })}
                >
                  <Form
                    formProps={formProps}
                    cost={cost}
                    isCompany={isCompany}
                    shippingProvider={shippingProvider}
                  />
                  <StockButtons
                    id={data.id}
                    formProps={formProps}
                    isRepeat={data.isRepeat}
                    loading={loading}
                    setLoading={setLoading}
                    updateModal={updateModal}
                    setUpdateModal={setUpdateModal}
                  />
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
