import {useMount as _useMount} from 'react-use';
import notify from '@/migration/notification';
import lQs from 'qs';
import _ from 'lodash';

import paths from '@/config/paths';
import utils from '@/lib/utils';
import api from '@/api';

export const useMount = (
  props: any,
  setActiveDimmer: any,
  setIsFacebookLogin: any,
  setIsGoogleLogin: any
) => {
  _useMount(async () => {
    try {
      const windowQs = window.location.search;
      const strQs = windowQs.slice(1);
      const qs = lQs.parse(strQs);

      // SNSログイン・新規登録の場合
      if (_.has(qs, 'code')) {
        setActiveDimmer(true);
        // SNSから取得したトークンを追加
        await utils.auth.setToken(qs.code);
        await api.users.getMe();

        window.location.assign(paths.sales.main);
      }
    } catch (err: any) {
      if (err.response.data.error) {
        switch (err.response.data.error) {
          case 'invalid_grant':
            notify('FishSaleに登録されていないユーザーです', 'error');
            break;
        }
      } else {
        // TODO 既に登録しているユーザーはエラーを出して、Cognito消し込む
        // SNSログインボタン押下後に作成されたCognitoUserを取得する
        // const user = await utils.amplify.getUser();
        // try {
        //   // SNSに登録しているユーザーのメールアドレスが重複していないかチェックする
        //   await api.users.isLoginUsersUnique({email: user.attributes.email});
        //   setIsSignUpModal(true);
        // } catch (error) {
        //   switch (error.response.data.message) {
        //     case 'An account with the given email already exists.':
        //       notify(constants.ERRORS.EXISTED_EMAIL, 'error');
        //       // 作成されたCognitoUserを削除する
        //       // meが作成されていない為userIdではなく、Cognitoのusernameを使用する
        //       api.users.deleteCognitoUser(
        //         user.signInUserSession.accessToken.payload.username
        //       );
        //   }
        // }
      }
    } finally {
      setActiveDimmer(false);
      setIsGoogleLogin(false);
      setIsFacebookLogin(false);
    }
  });
};
