import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import api from '@/api';
import utils from '@/lib/utils';

type Props = {
  data: StocksStore;
  pagination: Pagination;
};

const initialState: Props = {
  data: {
    area: 0,
    bid: false,
    bidCount: 0,
    biddable: false,
    bidderUserId: 0,
    boughtUserId: 0,
    capacity: 0,
    cleaningMethod: 0,
    closeBidAt: '',
    closeBidMin: 0,
    closed: false,
    closedAt: '',
    closedReason: 0,
    count: 0,
    createdAt: '',
    deliveryMethod: 0,
    displayPrice: 0,
    explanation: '',
    fishKind: '',
    fishingDay: '',
    fishingSpot: 0,
    fishingSpotDetail: '',
    fixPrice: 0,
    fixed: false,
    fixedAt: '',
    hideFishingSpot: false,
    isLandAfterFix: false,
    id: 0,
    ikejimeMethod: 0,
    isIkejime: false,
    ikejimeWater: 0,
    ikejimeTiming: 0,
    imageJson: [],
    infoUpdatedAt: '',
    isDeleted: false,
    isDummy: false,
    isEnabled: false,
    isNature: false,
    isRepeat: false,
    isVacuum: false,
    price: 0,
    repeatLimitDate: '',
    savingMethod: 0,
    sellerUserId: 0,
    shippingExpense: 0,
    shippingSize: 0,
    shippingTiming: 0,
    size: 0,
    startPrice: 0,
    stripeChargeId: '',
    updatedAt: '',
    weight: 0,
    weightAdditionalInfo: '',
  },
  pagination: {
    data: {
      active: [],
      after_distant: [],
      after_near: [],
      before_distant: [],
      before_near: [],
      first: [],
      last: [],
    },
    sort: '-id',
    current: 1,
    totalCount: 0,
    totalPages: 0,
    disabled: false,
  },
};

/** ペーネーション */
export const fetchStocksPaging = createAsyncThunk(
  'stocks/fetchPagination',
  async (params: GetStocksPagingParams) => {
    const res = await api.stocks.getStocksPaging(params);
    const {data, headers} = utils.mapKeysCamelCase.default(res);

    return {data, headers, current: params.page};
  }
);

/** 詳細取得 */
export const getOneStock = createAsyncThunk(
  'stocks/getOneStock',
  async (stockId: number) => {
    const res = await api.stocks.getOneStock(stockId);
    const {data} = utils.mapKeysCamelCase.default(res);

    return {data};
  }
);

/** 取り下げ */
export const withDraw = createAsyncThunk(
  'stocks/withDrawOne',
  async ({
    stockId,
    isRepeat,
    isCanceled,
  }: {
    stockId: number;
    isRepeat: boolean;
    isCanceled: boolean;
  }) => {
    const res = await api.stocks.updateWithDraw(stockId, {
      isRepeat,
      isCanceled,
    });
    const {data} = utils.mapKeysCamelCase.default(res);

    return {data};
  }
);

const stocks = createSlice({
  name: 'stocks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStocksPaging.fulfilled, (state, {payload}) => {
      state.pagination.data = payload.data.pages;
      state.pagination.current = payload.current;
      state.pagination.totalCount = payload.headers['xTotalCount'];
      state.pagination.totalPages = payload.headers['xTotalPages'];
    });

    builder.addCase(getOneStock.fulfilled, (state, {payload}) => {
      state.data = payload.data;
    });

    builder.addCase(withDraw.fulfilled, (state, {payload}) => {
      state.data = payload.data;
    });
  },
});

export default stocks.reducer;
