import utils from '@/lib/utils';

export type CreateUsersRate = {
  dist_user_id: number;
  sale_id: number;
  point: number;
  comment: string;
};

/**
 * me取得
 */
export const getMe = async (): Promise<any> => {
  const res = await utils.axios.api().get(`/v1/me`);

  return res.data;
};

/**
 * レーティング
 * @param userId 落札者のユーザーID (レビュー対象のユーザーID)
 */
export const rate = async (
  userId: number,
  b: CreateUsersRate
): Promise<any> => {
  const res = await utils.axios.api().post(`/v1/users/${userId}/rate`, b);

  return res.data;
};

interface RateCollectBody {
  rating_targets: Array<{
    sale_id: number;
    dist_user_id: number;
  }>;
  point: number;
  comment: string;
}

/**
 *
 */
export const rateCollect = async (
  meId: number,
  body: RateCollectBody
): Promise<any> => {
  const res = await utils.axios
    .api()
    .post(`/v1/users/${meId}/rate/collect`, body);

  return res.data;
};
