import {rateCollect} from '@/api/users';
import notify from '@/migration/notification';

interface Value {
  point: number;
  comment: string;
  checkbox: boolean;
}

interface RatingTargets {
  sale_id: number;
  dist_user_id: number;
}

export const submit = async (
  value: Value,
  ratingTargets: RatingTargets[],
  meId: number,
  formikProps: any,
  setRatingModal: React.Dispatch<React.SetStateAction<boolean>>,
  parentFormProps: any
) => {
  if (!value.checkbox) {
    notify('チェックボックスにチェックしてください', 'error');
    return;
  }

  if (!value.point) {
    notify('星1~5で採点してください。', 'error');
    return;
  }

  try {
    const body = {
      rating_targets: ratingTargets,
      point: value.point,
      comment: value.comment,
    };
    await rateCollect(meId, body);
    notify('評価を登録しました', 'success');

    parentFormProps.setFieldValue('selections', []);
    parentFormProps.setFieldValue('ratingTargets', []);
  } catch (error) {
    notify('評価に失敗しました', 'error');
  } finally {
    formikProps.setSubmitting(false);
    setRatingModal(false);
  }
};
