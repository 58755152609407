import utils from '@/lib/utils';
import {AxiosPromise} from 'axios';

export const getStocksStockId = ({
  stockId,
}: GetStocksStockIdParams): AxiosPromise<GetStocksStockIdData> =>
  utils.axios.api().get(`/v1/stocks/${stockId}`);

export const putStocksStockId = ({
  stockId,
  ...params
}: any): AxiosPromise<PutStocksStockIdData> =>
  utils.axios
    .api()
    .put(`/v1/stocks/${stockId}`, utils.mapKeysSnakeCase.default(params));
