import React from 'react';
import utils from '@/lib/utils';
import {BrowserRouter} from 'react-router-dom';
import Routes from '@/routes';

utils.amplify.init();

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};
