import utils from '@/lib/utils';
import {AxiosPromise} from 'axios';

export const putStocksAutoStockId = ({
  stockId,
  ...params
}: PutStocksAutoStockIdParams): AxiosPromise<PutStocksAutoStockIdData> =>
  utils.axios
    .api()
    .put(`/v1/stocks_auto/${stockId}`, utils.mapKeysSnakeCase.default(params));
