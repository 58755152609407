import notify from '@/migration/notification';
import {withDraw} from '@/redux/stockSlice';

/** 取り下げる */
export const withDrawOne = async (
  stockId: number,
  dispatch: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);

    dispatch(
      withDraw({
        stockId,
        isRepeat: true,
        isCanceled: true,
      })
    );

    notify('出品中の商品を取り下げました', 'success');
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    setLoading(false);
    setWithdrawModal(false);
  }
};

/** すべて取り下げる */
export const allWithDraw = async (
  stockId: number,
  dispatch: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAllWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);

    dispatch(
      withDraw({
        stockId,
        isRepeat: false,
        isCanceled: true,
      })
    );

    notify('自動再出品を停止し、出品中の商品を取り下げました', 'success');
  } catch (error: any) {
    notify(error.message, 'error');
  } finally {
    setLoading(false);
    setAllWithdrawModal(false);
  }
};
