import paths from '@/config/paths';
import {mergeDate} from '@/lib/utils/date';
import notify from '@/migration/notification';
import {updateStocks} from '@/api/stocks';

type StockParams = {
  area: number;
  capacity: number;
  cleaningMethod: number;
  closeBidMin: number;
  count: number;
  deliveryMethod: number;
  explanation: string;
  fishKind: string;
  fishingDay: string;
  fishingSpot: number;
  fishingSpotDetail: string;
  fixPrice?: number;
  ikejimeMethod: number;
  ikejimeTiming: number;
  ikejimeWater: number;
  imageJson: string[];
  isDummy: boolean;
  isIkejime: boolean;
  isLandAfterFix: boolean;
  isNature: boolean;
  isVacuum: boolean;
  repeatLimitDate: string;
  savingMethod: number;
  sellerUserId: number;
  shippingExpense: number;
  shippingSize: number;
  shippingTiming?: number;
  size: number;
  startPrice: number;
  weight?: number;
  weightAdditionalInfo: string;
};

const toInt = (str: string) => {
  return str ? parseInt(str, 10) : 0;
};

const createParams = (values: any) => {
  let fishingDay = '';

  if (
    values.fishingDate.year &&
    values.fishingDate.month &&
    values.fishingDate.day
  ) {
    fishingDay = mergeDate(
      values.fishingDate.year,
      values.fishingDate.month,
      values.fishingDate.day,
      'YYYY-MM-DD'
    );
  }

  const stockParams: StockParams = {
    area: values.area,
    capacity: toInt(values.capacity),
    cleaningMethod: values.cleaningMethod,
    closeBidMin: values.closeBidMin * 60,
    count: 0,
    deliveryMethod: values.deliveryMethod,
    explanation: values.explanation,
    fishKind: values.fishKind,
    fishingDay,
    fishingSpot: toInt(values.fishingSpot),
    fishingSpotDetail: values.fishingSpotDetail,
    ikejimeMethod: values.ikejimeMethod,
    ikejimeTiming: values.ikejimeTiming,
    ikejimeWater: values.ikejimeWater,
    imageJson: values.imageJson,
    isDummy: values.isDummy,
    isIkejime: values.isIkejime,
    isLandAfterFix: values.isLandAfterFix,
    isNature: values.isNature,
    isVacuum: values.isVacuum,
    repeatLimitDate: mergeDate(
      values.repeatLimitDate.year,
      values.repeatLimitDate.month,
      values.repeatLimitDate.day,
      'YYYY-MM-DD'
    ),
    savingMethod: values.savingMethod,
    sellerUserId: values.sellerUserId,
    shippingExpense: values.shippingExpense,
    shippingSize: values.shippingSize,
    shippingTiming: values.shippingTiming,
    size: toInt(values.size),
    startPrice: toInt(values.startPrice),
    weightAdditionalInfo: values.weightAdditionalInfo,
  };

  if (values.weight) stockParams.weight = toInt(values.weight);

  if (values.fixPrice) stockParams.fixPrice = toInt(values.fixPrice);

  if (values.shippingTiming === 3)
    stockParams.shippingExpense = values.shippingTiming;

  return {stockParams};
};

export const submit = async (
  stockId: number,
  value: any,
  actions: any,
  history: any,
  setModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    actions.setSubmitting(true);

    const {stockParams} = createParams(value);

    await updateStocks(stockId, stockParams);

    notify('在庫の変更内容を保存しました。', 'success');
    history.push(paths.stocks.main);
  } catch (error: any) {
    switch (error?.message) {
      case 'contain ban words':
        notify('不適切な表現が含まれています。', 'error', undefined, 5);
        break;

      case 'number of sale limit per week exceeded':
      case 'you must be a company to shipping-expense-for-sender-pay':
        notify(
          '月曜～日曜の出品上限数（乱獲防止対策）を超えたため、出品できません。',
          'error',
          undefined,
          5
        );
        break;

      case 'contain inappropriate images':
      case 'contain human images':
      case 'contain denial fish images':
      case 'uncontain fish images':
        notify(
          '不適切な画像が含まれているため、出品できません。',
          'error',
          undefined,
          5
        );
        break;
    }
  } finally {
    actions.setSubmitting(false);
    setModal(false);
  }
};
