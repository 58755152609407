import {useEffect} from 'react';
import {State} from './data';
import moment from 'moment';

// 年が0始まりの場合、無効な日付と判断する
const INVALID_DATES = /000\d+/;
const MAX_PRICE = 2147483647;

/** 日付フォーマット */
export const format = (base: string, format?: string) => {
  const dt = moment(base).format(format);

  if (dt === 'Invalid date') return '';

  return dt;
};

const getFishingDate = (date: string) => {
  // 無効な日付
  if (INVALID_DATES.test(date))
    return {
      year: '',
      month: '',
      day: '',
    };

  return {
    year: format(date, 'YYYY'),
    month: parseInt(format(date, 'M'), 10),
    day: parseInt(format(date, 'D'), 10),
  };
};

export const useSetData = (
  data: StocksStore,
  setInitialValues: React.Dispatch<React.SetStateAction<State>>
) => {
  useEffect(() => {
    const {
      fixPrice,
      closeBidMin,
      size,
      weight,
      fishingDay,
      imageJson,
      repeatLimitDate,
      shippingSize,
    } = data;

    const updateData = {
      ...data,
      fixPrice: Number(fixPrice) === MAX_PRICE ? '' : fixPrice,
      closeBidMin: Number(closeBidMin) / 60,
      size: size ? size : '',
      weight: weight ? weight : '',
      fishingDate: getFishingDate(fishingDay),
      shippingSize: shippingSize ? shippingSize : 60,
      imageJson: imageJson || [],
      repeatLimitDate: {
        year: format(repeatLimitDate, 'YYYY'),
        month: parseInt(format(repeatLimitDate, 'M'), 10),
        day: parseInt(format(repeatLimitDate, 'D'), 10),
      },
    };

    setInitialValues(updateData);
  }, [data, setInitialValues]);
};
