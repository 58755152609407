import utils from '@/lib/utils';
import {AxiosError} from 'axios';

/** 自動再出品データの登録 */
export const createStocks = (params: any): Promise<any> =>
  utils.axios.api().post('/v1/stocks', utils.mapKeysSnakeCase.default(params));

/** 在庫データ更新 */
export const updateStocks = async (
  stockId: number,
  body: any
): Promise<any> => {
  try {
    const res = await utils.axios
      .api()
      .put(`/v1/stocks/${stockId}`, utils.mapKeysSnakeCase.default(body));

    return res;
  } catch (error) {
    const e = error as AxiosError['response'];
    throw new Error(e?.data?.errors?.[0]?.message);
  }
};

/** 在庫データ取得 */
export const getOneStock = async (stockId: number): Promise<any> => {
  try {
    const res = await utils.axios.api().get(`/v1/stocks/${stockId}`);

    return res;
  } catch (error) {
    console.error(error);
  }
};

/** ページネーション */
export const getStocksPaging = async (
  params: GetStocksPagingParams
): Promise<any> => {
  try {
    const res = await utils.axios.api().get('/v1/stocks_paging', {params});
    return res;
  } catch (error) {
    console.error(error);
  }
};

/** 取り下げ */
export const updateWithDraw = async (
  stockId: number,
  body: UpdateStocksAuto
): Promise<any> => {
  try {
    const res = await utils.axios
      .api()
      .put(`/v1/stocks_auto/${stockId}`, utils.mapKeysSnakeCase.default(body));

    return res;
  } catch (error) {
    console.error(error);
  }
};
