import React from 'react';
import {
  Field,
  FormikProps,
  FormikValues,
  FieldProps,
  ErrorMessage,
} from 'formik';

import Dropzone from '../Dropzone';
import {now} from '@/lib/utils/date';

import {
  Input,
  Label,
  Dropdown,
  Checkbox,
  SemanticTextArea,
} from '@/components/Form';

// OPTIONS
import {
  fishingSpots,
  ikejimeMethods,
  ikejimeWaters,
  cleaningMethods,
  ikejimeTimings,
  savingMethods,
  shippingExpenses,
  deliveryMethods,
  shippingTimings,
  shippingSizes,
  areas,
  closeBidMins,
  years,
  featureYears,
  months,
  days,
} from '@/config/constants/options';

import './index.scss';

interface Props {
  formProps: FormikProps<FormikValues>;
  exhibit?: boolean;
  isCompany: boolean;
  shippingProvider: number;
  cost: {id: number; userId: number};
}

const Form: React.FC<Props> = ({
  exhibit,
  formProps,
  cost,
  isCompany,
  shippingProvider,
}) => {
  const {values} = formProps;

  return (
    <form className="b__stocks-detail__form-main">
      <Dropzone name="imageJson" disabled={exhibit} />

      <div className="b__field">
        <Label text="商品名 (最大50文字)" required />
        <Field
          name="fishKind"
          component={Input.Default}
          maxLength="50"
          placeholder="例）タイ"
          disabled={exhibit}
        />
        {formProps.errors['fishKind'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="fishKind" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="サイズ (最大10,000cm)" optional />
        <Field
          name="size"
          component={Input.Default}
          maxLength="5"
          placeholder="例）30"
          label={{basic: true, content: 'cm'}}
          labelPosition="right"
          disabled={exhibit}
        />
        {formProps.errors['size'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="size" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="重量 (最大10,000,000g)" optional />
        <Field
          name="weight"
          component={Input.Default}
          maxLength="8"
          placeholder="例) 1500"
          label={{basic: true, content: 'g'}}
          labelPosition="right"
          disabled={exhibit}
        />
        {formProps.errors['weight'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="weight" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Field
          name="weightAdditionalInfo"
          component={Input.Default}
          maxLength="50"
          placeholder="備考）頭は落としています"
          disabled={exhibit}
        />
        <div className="b__paragraph">(最大50文字)</div>
      </div>

      <div className="b__field">
        <Label text="釣り場（産地）" optional />
        <Field
          name="fishingSpot"
          component={Dropdown}
          fluid
          options={fishingSpots}
          selection
          disabled={exhibit}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
          placeholder="選択してください"
        />
      </div>

      <div className="b__field">
        <Field
          name="fishingSpotDetail"
          component={Input.Default}
          maxLength="50"
          disabled={exhibit}
          placeholder="詳細ポイント）明石海峡・加太 など"
        />
      </div>

      <div className="b__field">
        <Label text="釣り上げた日（水揚げ日）" optional />
        <div className="b__field__row">
          <div className="b__field__column">
            <Field
              name="fishingDate.year"
              component={Dropdown}
              fluid
              selection
              placeholder={now('YYYY')}
              disabled={exhibit}
              options={years}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>年</span>
          </div>
          <div className="b__field__column">
            <Field
              name="fishingDate.month"
              component={Dropdown}
              fluid
              selection
              placeholder={now('M')}
              disabled={exhibit}
              options={months}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>月</span>
          </div>
          <div className="b__field__column">
            <Field
              name="fishingDate.day"
              component={Dropdown}
              fluid
              selection
              placeholder={now('D')}
              disabled={exhibit}
              options={days}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>日</span>
          </div>
        </div>
      </div>

      <div className="b__field__m0">
        <Field
          name="isLandAfterFix"
          label="落札後水揚げ"
          component={Checkbox}
          disabled={exhibit}
          checked={formProps.values.isLandAfterFix}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
        />
      </div>

      <div className="b__field">
        <Label text="天然・養殖の選択" required />
        <Field name="isNature">
          {({field}: FieldProps) => (
            <div className="b__field__radio isNature">
              <Checkbox
                label="天然"
                radio
                disabled={exhibit}
                checked={field.value}
                onClick={() => {
                  formProps.setFieldValue('isNature', true);
                }}
              />
              <Checkbox
                label="養殖"
                radio
                disabled={exhibit}
                checked={!field.value}
                onClick={() => {
                  formProps.setFieldValue('isNature', false);
                }}
              />
            </div>
          )}
        </Field>
      </div>

      <div className="b__field">
        <Label text="締め方" required />
        <Field
          name="ikejimeMethod"
          component={Dropdown}
          fluid
          options={ikejimeMethods}
          selection
          disabled={exhibit}
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['ikejimeMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="ikejimeMethod" />
          </a>
        ) : null}
      </div>

      <div className="b__field__m0">
        <Field
          name="isIkejime"
          label="活〆"
          disabled={exhibit}
          component={Checkbox}
          checked={formProps.values.isIkejime}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
        />
      </div>

      <div className="b__field">
        <Label text="処理方法" required />
        <Field
          name="cleaningMethod"
          component={Dropdown}
          fluid
          options={cleaningMethods}
          selection
          disabled={exhibit}
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['cleaningMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="cleaningMethod" />
          </a>
        ) : null}
        <div className="ikejimeWater">
          <Field name="ikejimeWater">
            {({field}: FieldProps) => (
              <>
                {ikejimeWaters.map((ikejimeWater, index) => (
                  <Checkbox
                    label={ikejimeWater.text}
                    radio
                    disabled={exhibit}
                    value={ikejimeWater.value}
                    checked={ikejimeWater.value === field.value}
                    onClick={(_: any, {value}) => {
                      formProps.setFieldValue('ikejimeWater', value);
                    }}
                    key={index}
                  />
                ))}
              </>
            )}
          </Field>
        </div>
      </div>

      <div className="b__field">
        <Label text="締め処理を行なったタイミング" optional />
        <Field
          name="ikejimeTiming"
          component={Dropdown}
          fluid
          options={ikejimeTimings}
          selection
          disabled={exhibit}
          placeholder="選択してください"
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
      </div>

      <div className="b__field">
        <Label text="保管方法" required />
        <Field
          name="savingMethod"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={savingMethods}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['savingMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="savingMethod" />
          </a>
        ) : null}
      </div>

      <div className="b__field__m0">
        <Field
          name="isVacuum"
          label="真空パック保管"
          disabled={exhibit}
          component={Checkbox}
          checked={formProps.values.isVacuum}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.checked);
          }}
        />
      </div>

      <div className="b__field">
        <Label text="配送料の負担" required />
        <Field
          name="shippingExpense"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={shippingExpenses.filter(
            ({value}) =>
              value !== 3 ||
              (isCompany &&
                shippingProvider !== 0 &&
                cost.id !== 0 &&
                cost.userId !== 0)
          )}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['shippingExpense'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="shippingExpense" />
          </a>
        ) : null}
      </div>

      {formProps.values.shippingExpense === 3 ? (
        <div className="b__field">
          <Label text="配送サイズ区分" required />
          <Field
            name="shippingSize"
            component={Dropdown}
            disabled={exhibit}
            fluid
            placeholder="選択してください"
            selection
            options={shippingSizes}
            onChange={(_: any, field: any) => {
              formProps.setFieldValue(field.name, field.value);
            }}
          />
          {formProps.errors['shippingSize'] !== undefined ? (
            <a className="form__error">
              <ErrorMessage name="shippingSize" />
            </a>
          ) : null}
        </div>
      ) : null}

      <div className="b__field">
        <Label text="配送方法" required />
        <Field
          name="deliveryMethod"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={deliveryMethods}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['deliveryMethod'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="deliveryMethod" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="発送までの日数" required />
        <Field
          name="shippingTiming"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={shippingTimings}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['shippingTiming'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="shippingTiming" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="発送元の地域" required />
        <Field
          name="area"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={areas}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['area'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="area" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品時の開始価格" required />
        <Field
          name="startPrice"
          component={Input.Default}
          maxLength="8"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '¥'}}
          labelPosition="left"
        />
        {formProps.errors['startPrice'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="startPrice" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="即決価格" optional />
        <Field
          name="fixPrice"
          component={Input.Default}
          maxLength="8"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '¥'}}
          labelPosition="left"
        />
        {formProps.errors['fixPrice'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="fixPrice" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品期限" required />
        <Field
          name="closeBidMin"
          component={Dropdown}
          fluid
          selection
          disabled={exhibit}
          placeholder="選択してください"
          options={closeBidMins}
          onChange={(_: any, field: any) => {
            formProps.setFieldValue(field.name, field.value);
          }}
        />
        {formProps.errors['closeBidMin'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="closeBidMin" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="出品魚説明" required />
        <Field
          name="explanation"
          component={SemanticTextArea}
          rows="5"
          disabled={exhibit}
          maxLength="1000"
        />
        <div>(最大1,000文字)</div>
        {formProps.errors['explanation'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="explanation" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text={`繰り返し回数（出品した回数 ${values.count}）`} />
        <Field
          name="capacity"
          component={Input.Default}
          maxLength="10"
          disabled={exhibit}
          placeholder="入力してください"
          label={{basic: true, content: '回'}}
          labelPosition="right"
        />
        {formProps.errors['capacity'] !== undefined ? (
          <a className="form__error">
            <ErrorMessage name="capacity" />
          </a>
        ) : null}
      </div>

      <div className="b__field">
        <Label text="繰り返し期限" />
        <div className="b__field__row">
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.year"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={featureYears}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>年</span>
          </div>
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.month"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={months}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>月</span>
          </div>
          <div className="b__field__column">
            <Field
              name="repeatLimitDate.day"
              component={Dropdown}
              fluid
              selection
              disabled={exhibit}
              options={days}
              onChange={(_: any, field: any) => {
                formProps.setFieldValue(field.name, field.value);
              }}
            />
            <span>日</span>
          </div>
        </div>
        {formProps.errors['repeatLimitDate'] !== undefined ? (
          <a className="form__error">{formProps.errors.repeatLimitDate}</a>
        ) : null}
      </div>
    </form>
  );
};

export default Form;
