export interface State {
  imageJson: string[];
  fishKind: string;
  size: string | number;
  weight: string | number;
  weightAdditionalInfo: string;
  fishingSpot: string | number;
  fishingSpotDetail: string;
  fishingDate: {
    year: string | number;
    month: string | number;
    day: string | number;
  };
  isLandAfterFix: boolean;
  isNature: boolean;
  ikejimeMethod: string | number;
  isIkejime: boolean;
  ikejimeWater: string | number;
  cleaningMethod: string | number;
  ikejimeTiming: string | number;
  savingMethod: string | number;
  isVacuum: boolean;
  shippingExpense: string | number;
  shippingSize: string | number;
  deliveryMethod: string | number;
  shippingTiming: string | number;
  area: string | number;
  startPrice: string | number;
  fixPrice: string | number;
  closeBidMin: string | number;
  explanation: string;
  count: number;
  capacity: string | number;
  repeatLimitDate: {
    year: string | number;
    month: string | number;
    day: string | number;
  };
}

export const initialState: State = {
  imageJson: [],
  fishKind: '',
  size: '',
  weight: '',
  weightAdditionalInfo: '',
  fishingSpot: '',
  fishingSpotDetail: '',
  fishingDate: {
    year: '',
    month: '',
    day: '',
  },
  isLandAfterFix: false,
  isNature: true,
  ikejimeMethod: '',
  isIkejime: false,
  ikejimeWater: '',
  cleaningMethod: '',
  ikejimeTiming: '',
  savingMethod: '',
  isVacuum: false,
  shippingExpense: '',
  shippingSize: 60,
  deliveryMethod: '',
  shippingTiming: '',
  area: '',
  startPrice: '',
  fixPrice: '',
  closeBidMin: '',
  explanation: '',
  capacity: '',
  count: 0,
  repeatLimitDate: {
    year: '',
    month: '',
    day: '',
  },
};
