import api from '@/api';

export const getCsv = async (saleIds: number[], formProps: any) => {
  try {
    const qp = saleIds.map((id: number) => {
      return id;
    });

    const csv = await api.csv.exportCsv(qp);
    // Excelでも見れるように
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    // APIから取得したCSVをダウンロードするurlを作成
    const url = URL.createObjectURL(new Blob([bom, csv]));
    // aタグを擬似的に作成
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '出品魚一覧.csv');
    document.body.appendChild(link);
    link.click();

    formProps.setFieldValue('selections', []);
    formProps.setFieldValue('ratingTargets', []);
  } catch (error) {
    console.error(error);
  }
};
